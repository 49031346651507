import {Col, List, Row, Space, Tag, Tooltip} from 'antd';
import {CheckOutlined, CloseOutlined, EditOutlined, TagOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Store
import * as storeSelectors from '../../store/redux/selectors/storeSelectors';

// Utils
import {useScreenSizeValidator} from "../../utils/generalUtils";

// Types
import { AllianceStoreType, StoreType } from '../../types/StoreTypes';

type StoreListType = {
  onToggleModal: (visible: boolean, allianceToEdit: StoreType) => void;
};
const StoreList = (props: StoreListType) => {
  const isLG = useScreenSizeValidator('(min-width: 992px)');
  const response = useSelector(storeSelectors.allStoresSelector)!;

  return (
    <List dataSource={response.rows} grid={{ gutter: 24, column: isLG ? 2 : 1 }}
          renderItem={(store) => (
            <List.Item>
              <Row className={'flex py-2'}>
                <Col sm={12} md={16} xs={16}>
                  <p className={'font-medium'}>
                    <TagOutlined title={'Nombre'} className={'mr-2'} />
                    {store?.name}
                  </p>
                  <p className={`${store.isActive ? 'text-green-dkt-600' : 'text-red-dkt-300'}`}>
                    {store?.isActive
                      ? <CheckOutlined title={'Estado'} className={'mr-2'} />
                      : <CloseOutlined title={'Estado'} className={'mr-2'} />
                    }
                    {store?.isActive ? 'Activo' : 'Inactivo'}
                  </p>
                  <Col md={24}>
                    {store?.allianceStores?.map((allianceStore : AllianceStoreType, index) =>
                      <Tag className={'text-white my-1'} color={allianceStore.alliance?.color}
                          key={index} closable={false}>
                        {allianceStore.alliance?.name}
                      </Tag>
                    )}
                  </Col>
                </Col>
                <Col xs={0} md={8} className={'my-auto text-right'}>
                  <Space size={'middle'}>
                    <Tooltip title={'Editar'}>
                      <EditOutlined className={'inline-block mx-2 text-blue-dkt-400'}
                                    onClick={() => props.onToggleModal(true, store)}/>
                    </Tooltip>
                  </Space>
                </Col>
                <Col xs={8} md={0} className={'my-auto text-center'}>
                  <Space size={'large'}>
                    <p className={'text-blue-dkt-400 cursor-pointer'}
                       onClick={() => props.onToggleModal(true, store)}>
                      <EditOutlined /> Editar
                    </p>
                  </Space>
                </Col>
              </Row>
            </List.Item>
          )}
    />
  );
}

export default StoreList;