import request from './requestWrapper';

// Types
import {FiltersPaginationType} from '../types/SharedTypes';
import {AllianceStockType} from "../types/AllianceStockTypes";

function getParams() {
  return request({
    url: 'catalog/getParams',
    method: 'GET',
  });
}
function getDetail(model: string) {
  return request({
    url: `catalog/getDetail/${model}`,
    method: 'GET',
  });
}
function getTemplate() {
  return request({
    url: 'catalog/getTemplate',
    method: 'GET',
  });
}
function getExcludeCatalogTemplate() {
  return request({
    url: 'catalog/getExcludeCatalogTemplate',
    method: 'GET',
  });
}
function getAll(pagination?: FiltersPaginationType) {
  return request({
    url: 'catalog/getAll',
    method: 'POST',
    data: pagination
  });
}
function upload(formData: FormData) {
  return request({
    url: 'catalog/upload',
    method: 'POST',
    data: formData
  });
}
function uploadCatalogToExclude(formData: FormData) {
  return request({
    url: 'catalog/uploadCatalogToExclude',
    method: 'POST',
    data: formData
  });
}
function update(stock: AllianceStockType) {
  return request({
    url: 'catalog/update',
    method: 'PUT',
    data: stock
  });
}

export default {
  getParams, getDetail, getTemplate, getExcludeCatalogTemplate,
  getAll, upload, uploadCatalogToExclude, update
}