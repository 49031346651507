// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import categoryServices from '../../../api/categoryServices';

// Types
import {categoryTypes} from '../types';
import {FiltersPaginationType} from '../../../types/SharedTypes';
import { CategoryType } from '../../../types/CategoryTypes';

// Constants
import baseURL from '../../../api/baseURL';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getParams = (pagination?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  categoryServices.getParams()
    .then((response) => {
      dispatch({
        type: categoryTypes.GET_CATEGORY_PARAMS,
        categoryParams: response.data
      });
      pagination && dispatch(getAll(pagination));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  categoryServices.getAll(data)
    .then((response) => {
      dispatch({
        type: categoryTypes.GET_ALL_CATEGORIES,
        categoryResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getCategoriesTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  categoryServices.getTemplate()
    .then((response) => {
      window.open(baseURL + response.data.excelUrl, '_blank');
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (category: CategoryType, data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  categoryServices.add(category)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const upload = (formData: FormData, data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  categoryServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (category: CategoryType, data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  categoryServices.update(category)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const remove = (categoryId: string, data?: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  categoryServices.remove(categoryId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}