import React, { useEffect, useState } from 'react';
import {Row, Col} from 'antd';
import {useSelector} from "react-redux";

// Types
import {FilterType, FiltersPaginationType} from "../../types/SharedTypes";
import {CatalogParamsType} from "../../types/CatalogTypes";

// Components and Subcomponents
import TitleComponent from '../subComponents/TitleComponent';
import ProductList from "./ProductList";
import FileManagement from './FileManagement';
import VerticalFilterComponent from '../subComponents/VerticalFilterComponent';
import UploadExcelModal from '../subComponents/UploadExcelModal';

// Store
import {useAppDispatch} from '../../store/store';
import {getParams, getAll, uploadCatalogToExclude} from "../../store/redux/actions/catalogActions";
import * as catalogSelectors from "../../store/redux/selectors/catalogSelectors";
import {upload as productStoreUpload} from "../../store/redux/actions/productStoreActions";
import {upload as catalogUpload} from "../../store/redux/actions/catalogActions";

// Utils
import {useScreenSizeValidator} from "../../utils/generalUtils";


const Catalog = () => {
  const [paginationData, setPaginationData] = useState<FiltersPaginationType>({
    paginationVm: {
      page: 1,
      size: 10,
    },
    filterVm: {}
  });
  const [filters, setFilters] = useState<FilterType[]>([
    { name: "codes", label: "Código:", value: undefined, fieldType: 'input' },
    { name: "models", label: "Modelo:", value: undefined, fieldType: 'input' },
    { name: "names", label: "Nombre:", value: undefined, fieldType: 'input' },
    { name: "brands", label: "Marcas:", value: undefined, fieldType: 'select',
      items: { list: [], idIdentifier: "id", valueIdentifier: "value" } },
    { name: "stores", label: "Tiendas:", value: undefined, fieldType: 'select',
      items: { list: [], idIdentifier: "id", valueIdentifier: "value" } },
    { name: "alliances", label: "Alianzas:", value: undefined, fieldType: 'select',
      items: { list: [], idIdentifier: "id", valueIdentifier: "value" } },
  ]);
  const [showUploadCatalogModal, setShowUploadCatalogModal] = useState(false);
  const [showUploadCatalogToExcludeModal, setShowUploadCatalogToExcludeModal] = useState(false);
  const [showUploadProductStoreModal, setShowUploadProductStoreModal] = useState(false);
  const catalogParams = useSelector(catalogSelectors.catalogParamsSelector)!;
  const isSmallScreen = useScreenSizeValidator('(max-width: 576px)');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getParams(paginationData));
  }, [paginationData]);

  useEffect(() => {
    let newFilters = filters;
    Object.keys(catalogParams).map(key => {
      const filterFound = newFilters.find(filter => filter.name === key)!;
      filterFound.items!.list = catalogParams[key as keyof CatalogParamsType];
    });
    setFilters(newFilters);
  }, [catalogParams]);

  const handleChangePagination = (page: number, pageSize: number) => {
    const newPagination = paginationData;
    newPagination.paginationVm.page = page;
    newPagination.paginationVm.size = pageSize;
    setPaginationData(newPagination);
    dispatch(getAll(paginationData))
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Catálogo'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'text-left'}>
        <VerticalFilterComponent filters={filters}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        additionalContent={
                          <FileManagement isSmallScreen={isSmallScreen} 
                            onToggleUploadCatalogModal={() => setShowUploadCatalogModal(true)}
                            onToggleUploadCatalogToExcludeModal={() => setShowUploadCatalogToExcludeModal(true)} 
                            onToggleUploadProductStoreModal={() => setShowUploadProductStoreModal(true)}
                          />
                        }
          />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <ProductList pagination={paginationData} onChangePagination={handleChangePagination} />
      </Col>

      {showUploadCatalogModal &&
        <UploadExcelModal title={'Cargar precios'} 
                          onToggleModal={() => setShowUploadCatalogModal(false)} 
                          upload={(formData) => dispatch(catalogUpload(formData))}
        />
      }
      {showUploadCatalogToExcludeModal &&
        <UploadExcelModal title={'Cargar catálogo a excluir'} 
                          onToggleModal={() => setShowUploadCatalogToExcludeModal(false)} 
                          upload={(formData) => dispatch(uploadCatalogToExclude(formData))}
        />
      }
      {showUploadProductStoreModal &&
        <UploadExcelModal title={'Cargar inventario'} 
                          onToggleModal={() => setShowUploadProductStoreModal(false)} 
                          upload={(formData) => dispatch(productStoreUpload(formData))}
        />
      }
    </Row>
  );
}

export default Catalog;