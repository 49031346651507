// Libraries
import {Col} from 'antd';
import {AuditOutlined, DownloadOutlined, GiftOutlined, UploadOutlined} from "@ant-design/icons";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";

// Store
import {useAppDispatch} from '../../store/store';
import {getCatalogTemplate, getExcludeCatalogTemplate} from '../../store/redux/actions/catalogActions';
import {getProductStoreTemplate} from '../../store/redux/actions/productStoreActions';


type FileManagementType = {
  isSmallScreen: Boolean,
  onToggleUploadCatalogModal: () => void;
  onToggleUploadCatalogToExcludeModal: () => void;
  onToggleUploadProductStoreModal: () => void;
};
const FileManagement = (props: FileManagementType) => {
  const dispatch = useAppDispatch();

  return (
    <Col md={24} xs={24} className={'my-4'}>
      <ButtonComponent text={props.isSmallScreen ? '' : 'Plantilla inventario'}
                      icon={props.isSmallScreen ? <><DownloadOutlined /> <AuditOutlined /></> : <DownloadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                        'm-0.5 text-blue-dkt-400 bg-white hover:bg-blue-dkt-100 border-blue-dkt-400 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-blue-dkt-300 '}
                      onClick={() => dispatch(getProductStoreTemplate())} />     
      <ButtonComponent text={props.isSmallScreen ? '' : 'Cargar inventario'}
                      icon={props.isSmallScreen ? <><UploadOutlined /> <AuditOutlined /></> : <UploadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                      'm-0.5 text-blue-dkt-400 bg-white hover:bg-blue-dkt-100 border-blue-dkt-400 focus:outline-none focus:ring-2 ' +
                      'focus:ring-offset-2 focus:ring-blue-dkt-300 '}
                      onClick={props.onToggleUploadProductStoreModal} />
      <ButtonComponent text={props.isSmallScreen ? '' : 'Plantilla catálogo a excluir'}
                      icon={props.isSmallScreen ? <><DownloadOutlined /> <GiftOutlined /></> : <DownloadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                        'm-0.5 text-blue-dkt-400 bg-white hover:bg-blue-dkt-100 border-blue-dkt-400 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-blue-dkt-300 '}
                      onClick={() => dispatch(getExcludeCatalogTemplate())} />
      <ButtonComponent text={props.isSmallScreen ? '' : 'Plantilla precios'}
                      icon={props.isSmallScreen ? <><DownloadOutlined /> <GiftOutlined /></> : <DownloadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                        'm-0.5 text-blue-dkt-400 bg-white hover:bg-blue-dkt-100 border-blue-dkt-400 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-blue-dkt-300 '}
                      onClick={() => dispatch(getCatalogTemplate())} />
      <ButtonComponent text={props.isSmallScreen ? '' : 'Cargar catálogo a excluir'}
                      icon={props.isSmallScreen ? <><UploadOutlined /> <GiftOutlined /></> : <UploadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                        'm-0.5 text-white bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-orange-dkt-300 '}
                      onClick={props.onToggleUploadCatalogToExcludeModal} />
      <ButtonComponent text={props.isSmallScreen ? '' : 'Cargar precios'}
                      icon={props.isSmallScreen ? <><UploadOutlined /> <GiftOutlined /></> : <UploadOutlined />}
                      className={`${props.isSmallScreen ? 'w-20' : 'w-fit'} ` +
                        'm-0.5 text-white bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                        'focus:ring-offset-2 focus:ring-orange-dkt-300 '}
                      onClick={props.onToggleUploadCatalogModal} />
    </Col>
  );
}

export default FileManagement;