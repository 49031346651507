// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import productStoreServices from '../../../api/productStoreServices';

// Constans
import baseURL from '../../../api/baseURL';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

export const upload = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  productStoreServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getProductStoreTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  productStoreServices.getTemplate()
    .then((response) => {
      window.open(baseURL + response.data.excelUrl, '_blank');
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
