// Libraries
import React, { useEffect } from 'react';
import {Row, Col, Form, Modal, Switch} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import { 
  getDetailById, 
  create, 
  update 
} from '../../store/redux/actions/storeActions';
import * as storeSelectors from '../../store/redux/selectors/storeSelectors';

// Types
import { StoreType } from '../../types/StoreTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import { useSelector } from 'react-redux';


type AllianceStoreModalType = {
  storeToEdit?: StoreType;
  onToggleModal: (visible: boolean) => void;
};
const AllianceStoreModal = (props: AllianceStoreModalType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const params = useSelector(storeSelectors.storeParamsSelector)!;
  const storeDetail = useSelector(storeSelectors.storeDetailSelector)!;
  const isEditing = props.storeToEdit !== undefined;  
  
  useEffect(() => {
    isEditing && dispatch(getDetailById(props.storeToEdit?.storeId));    
  }, []);

  useEffect(() => {
    if(isEditing){
      form.setFieldsValue(storeDetail)
    } else {
      params.alliances?.map((alliance, index) =>{
        form.setFieldValue(['alliances', `${index}`, 'name'], alliance.value);
        form.setFieldValue(['alliances', `${index}`, 'allianceId'], alliance.id);
      })
    } 
  }, [storeDetail]);

  const handleSubmit = (values: StoreType) => {
    if (isEditing) {
      values.storeId = storeDetail.storeId;
      dispatch(update(values));
    } else {
      values.isActive = true;
      dispatch(create(values));
    }

    props.onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} tienda`} open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => props.onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 bg-blue-dkt-400 text-white hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"} form={form} onFinish = {handleSubmit}
            initialValues={isEditing ? storeDetail : {}}
            >
        <Row gutter={10}>
          {isEditing &&
            <Col md={12} xs={24}>
              <FormItemComponent name={'isActive'} required={false} valuePropName={'checked'}
                                 child={<Switch checkedChildren={'Activa'} unCheckedChildren={'Inactiva'}
                                                className={'w-fit'} />}
              />
            </Col>
          }
          <Col md={24} xs={24}>
            <FormItemComponent name={'name'} label={'Nombre'} required={true}
                               child={<InputComponent/>}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'code'} label={'Código'} required={true}
                               child={<InputComponent/>}
            />
          </Col>
          {params.alliances?.map((allianceStore , index) => 
            <Col md={24} xs={24} key={index}>
              <Row gutter={10}>
                <>
                  <FormItemComponent name={['alliances', `${index}`, 'allianceId']} className={'hidden'}
                                     required={false} child={<InputComponent disabled={true}/>} />
                  <FormItemComponent name={['alliances', `${index}`, 'allianceStoreId']} className={'hidden'}
                                     required={false} child={<InputComponent disabled={true}/>} />
                </>
                <Col md={4} xs={4}>
                  <FormItemComponent name={['alliances', `${index}`, 'allianceStoreActive']} required={false} valuePropName={'checked'} 
                                    label={ index > 0 ? undefined : 'Estado' }
                                    child={<Switch checkedChildren={'Activa'} unCheckedChildren={'Inactiva'}
                                                    className={'w-fit'} />}
                  />
                </Col>
                <Col md={10} xs={10}>
                  <FormItemComponent name={['alliances', `${index}`, 'name']} 
                                    label={ index > 0 ? undefined : 'Nombre alianza'} 
                                    required={false} child={<InputComponent disabled={true}/>}
                  />
                </Col>
                <Col md={10} xs={10}>
                  <FormItemComponent name={['alliances', `${index}`, 'allianceStoreCode']} 
                                    label={ index > 0 ? undefined : 'Codigo alianza-tienda'} 
                                    required={false} child={<InputComponent/>}
                  />
                </Col>
              </Row>       
            </Col>
          )}   
        </Row>
      </Form>
    </Modal>
  );
}

AllianceStoreModal.propTypes = {
  storeToEdit:PropTypes.object,
  onToggleModal: PropTypes.func.isRequired,
};

export default AllianceStoreModal;